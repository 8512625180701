const mixin = {
  created() {
    if (this.getList && typeof this.getList === 'function') {
      this.getList();
    }
  },
  methods: {
    _deleteSelected() {
      const { selections } = this;
      if (!selections) {
        return false;
      }
      if (!selections.length) {
        this.$message.warning('请先勾选');
        return false;
      }
      this.$confirm('确定删除勾选数据吗?', '提示', {
        type: 'warning',
        showClose: false,
        closeOnClickModal: false,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            const ids = selections.map(item => item.id);
            this.deleteOrderInfo(ids).finally(() => {
              done();
              setTimeout(() => {
                instance.confirmButtonLoading = false;
              }, 300);
            });
          } else {
            done();
          }
        },
      })
        .then(() => {
          this.$message.success('操作成功');
        })
        .catch(() => {});
      return true;
    },
    _clearPagination() {
      if (this?.pagination) {
        Object.assign(this.pagination, {
          currentPage: 1,
        });
      }
    },
  },
};

export default mixin;
