<template>
  <div>
    <el-dialog
      :visible.sync="dialogVisible"
      :title="dialogTitle"
      width="500px"
      v-drag
      @closed="dialogClosed"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      append-to-body
    >
      <el-form
        ref="elform"
        :model="formData"
        label-width="150px"
        size="small"
        :rules="rules"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item label="商品估值金额" prop="assessmentAmount">
              <el-input
                placeholder="商品估值金额"
                v-model.trim="formData.assessmentAmount"
                @input="numberCtrol(formData, 'assessmentAmount', 2, 20)"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="续期天数" prop="renewalDays">
              <el-input
                placeholder="请输入续期天数"
                v-model.trim="formData.renewalDays"
                @input="numberCtrol(formData, 'renewalDays', 0, 10)"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="续期仓管费" prop="renewalFee">
              <div class="money">¥ {{ formData.renewalFee }}</div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          size="small"
          @click="submitForm"
          :loading="loading"
          >计算</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import utils, { numberCtrol } from '@/utils/utils';
import api from '@/api/RenewalFeeApi';

export default {
  name: 'RenewalFeeCalc',
  data() {
    return {
      dialogVisible: false,
      dialogTitle: '仓管费计算器',
      loading: false,
      formData: {
        assessmentAmount: 0,
        renewalDays: 0,
      },
      rules: {
        assessmentAmount: [
          { required: true, message: '请输入', trigger: 'change' },
        ],
        renewalDays: [{ required: true, message: '请输入', trigger: 'change' }],
      },
    };
  },
  watch: {},
  methods: {
    open() {
      this.dialogVisible = true;
    },
    numberCtrol,
    validMoney(form, prop) {
      this[form][prop] = utils.validMoney(this[form][prop]);
    },
    submitForm() {
      this.$refs.elform.validate(valid => {
        if (valid) {
          this.loading = true;
          const { assessmentAmount, renewalDays } = this.formData;
          api
            .renewalFeeCalc({
              assessmentAmount,
              renewalDays,
            })
            .then(res => {
              this.formData.renewalFee = res.result;
            })
            .catch(err => {
              console.log(err);
              this.$message.error(err.message);
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    dialogClosed() {
      this.$refs.elform.resetFields();
    },
    _deleteSelected() {
      const { selections } = this;
      if (!selections) {
        return false;
      }
      if (!selections.length) {
        this.$message.warning('请先勾选');
        return false;
      }
      this.$confirm('确定删除勾选数据吗?', '提示', {
        type: 'warning',
        showClose: false,
        closeOnClickModal: false,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            const ids = selections.map(item => item.id);
            this.deleteOrderInfo(ids).finally(() => {
              done();
              setTimeout(() => {
                instance.confirmButtonLoading = false;
              }, 300);
            });
          } else {
            done();
          }
        },
      })
        .then(() => {
          this.$message.success('操作成功');
        })
        .catch(() => {});
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
.money {
  color: red;
  font-size: 18px;
  font-weight: bold;
}
</style>
