<template>
  <div class="content">
    <l-toggle>
      <dynamic-form :formItems="formItems" :model.sync="model"></dynamic-form>
      <search-reset
        :loading="loading"
        :model.sync="model"
        @search="
          _clearPagination();
          getList();
        "
      ></search-reset>
    </l-toggle>
    <dynamic-table
      ref="dynamic-table"
      :loading="loading"
      :columns="columns"
      :tableData="tableData"
      :pagination.sync="pagination"
      :selections.sync="selections"
      :tools="tools"
      @update="getList"
    ></dynamic-table>
    <add-renewal-fee
      ref="add-renewal-fee"
      @refreshTable="refreshTable"
    ></add-renewal-fee>
    <renewal-fee-calc ref="renewal-fee-calc"></renewal-fee-calc>
  </div>
</template>

<script>
import LToggle from '../../components/LToggle.vue';
import DynamicForm from '../../components/DynamicForm.vue';
import SearchReset from '../../components/SearchReset.vue';
import DynamicTable from '../../components/DynamicTable.vue';
import baseMixin from '../../mixins/base-mixin';
import api from '../../api/RenewalFeeApi';
import AddRenewalFee from './RenewalFee/AddRenewalFee.vue';
import RenewalFeeCalc from './RenewalFee/RenewalFeeCalc.vue';

export default {
  name: '',
  components: {
    RenewalFeeCalc,
    AddRenewalFee,
    LToggle,
    DynamicForm,
    SearchReset,
    DynamicTable,
  },
  mixins: [baseMixin],
  data() {
    return {
      model: {},
      formItems: [],
      columns: [
        {
          type: 'selection',
          fixed: 'left',
        },
        {
          type: 'index',
          label: '序号',
        },
        {
          label: '估值下限',
          prop: 'minAssessment',
        },
        {
          label: '估值上限',
          prop: 'maxAssessment',
        },
        {
          label: '7天仓管费比例（商品估值基数）',
          prop: 'feeRatio',
          valueSuffix: '%',
        },
        {
          label: '操作',
          prop: '_action',
          fixed: 'right',
          actions: [
            {
              title: '编辑',
              type: 'text',
              icon: 'el-icon-edit',
              event: this.modify,
              id: 'edit',
            },
            {
              title: '删除',
              type: 'text',
              icon: 'el-icon-delete',
              event: this.delete,
            },
          ],
        },
      ],
      tableData: [],
      loading: false,
      pagination: {
        total: 0,
        pageSize: 30,
        currentPage: 1,
      },
      selections: [],
      tools: [
        {
          title: '添加续期仓管费配置',
          type: 'primary',
          icon: 'el-icon-plus',
          event: this.add,
        },

        {
          title: '删除选择',
          type: 'danger',
          icon: 'el-icon-delete',
          event: this._deleteSelected,
        },
      ],
    };
  },
  computed: {},
  created() {},
  methods: {
    getList() {
      this.loading = true;
      const promise = new Promise((resolve, reject) => {
        const { model, pagination } = this;
        const { currentPage, pageSize } = pagination;
        const ext = {
          pageNum: currentPage,
          pageSize,
          ...model,
        };
        api
          .getRenewalFeeList(ext)
          .then(res => {
            const { datas, totalRecords } = res.result;
            this.tableData = datas;
            this.pagination.total = totalRecords;
            resolve(res);
          })
          .catch(e => {
            reject(e);
          })
          .finally(() => {
            this.loading = false;
          });
      });
      return promise;
    },
    refreshTable() {
      this.pagination.currentPage = 1;
      this.getList();
    },
    deleteRenewalFee(ids) {
      const promise = new Promise((resolve, reject) => {
        const ext = {
          idList: ids,
        };
        api
          .deleteRenewalFee(ext)
          .then(res => {
            this.getList();
            resolve(res);
          })
          .catch(e => {
            reject(e);
          })
          .finally(() => {});
      });
      return promise;
    },
    delete(row) {
      this.$confirm('确定删除该条数据吗?', '提示', {
        type: 'warning',
        showClose: false,
        closeOnClickModal: false,
        confirmButtonText: '确定',
        cancelButtonText: '取消',

        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            this.deleteRenewalFee([row.id]).finally(() => {
              done();
              setTimeout(() => {
                instance.confirmButtonLoading = false;
              }, 300);
            });
          } else {
            done();
          }
        },
      })
        .then(() => {
          this.$message.success('操作成功');
        })
        .catch(() => {});
    },
    add() {
      this.$refs['add-renewal-fee'].open();
    },
    modify(row) {
      this.$refs['add-renewal-fee'].modifyOpen(row);
    },
    calc() {
      this.$refs['renewal-fee-calc'].open();
    },
  },
};
</script>

<style lang="scss">
</style>
